.title
  margin: 0
  padding: 0
  &-big
    font-size: 3em
    font-weight: 700
    &.mini
      font-size: 2em
      margin-top: -20px !important
  &-intro
    color: lighten($marine, 30%)
    +v-sm
      font-size: 1.7em
    +v-xs
      font-size: 1.25em
      text-align: center
      line-height: 1.4
    &.desc
      margin-top: 24px
      margin-right: 16px
      color: $intro
      font-size: 1.4em
      font-weight: 400
      line-height: 1.5
      +v-sm
        margin: 0 !important
        padding: 0 !important
  &-part
    font-size: 1.5em
    font-weight: 500
    line-height: 1.5
    +v-xs
      font-size: 1.2em
  &-sub
    font-size: 1.05em
    font-weight: 600
    line-height: 1.75
    +v-xs
      max-width: 80% !important
      font-size: 1em !important
      text-align: center !important
      margin-left: auto !important
      margin-right: auto !important
      &.c-grey
        max-width: 100% !important
        line-height: 1.4 !important
        text-align: left !important
  +v-xs
    max-width: 70% !important
    font-size: 1.25em !important
    text-align: center !important
    margin-left: auto !important
    margin-right: auto !important

.txt
  font-size: 1em
  line-height: 1.5
  &-intro
    font-size: 1.05em
    font-weight: 400
    +v-xs
      text-align: center
      margin-bottom: -20px

footer
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
