
html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 92.5%
  font-family: $basic
  font-weight: 500
  font-smooth: always
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $white

a, * a
  color: inherit !important
  text-decoration: none !important

footer
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  img
    filter: grayscale(100%)
  +v-sm
    display: block
    padding: 20px
    height: auto
    min-height: auto !important
    text-align: center !important
  p,
  .tr
    +v-sm
      padding: 10px 0
      text-align: center !important

img
  +v-sm
    width: auto !important
    margin-left: auto !important
    margin-right: auto !important

.s-m-40
  display: none
  +v-sm
    display: block
    height: 40px

.container
  +v-sm
    padding: 0 10%

#top
  padding-top: 160px
  width: 100%
  height: auto
  min-height: 60vh
  background: #18131f url('../../assets/hero.webp') center right no-repeat
  background-blend-mode: overlay
  background-size: cover
  +v-sm
    padding: 200px 0 40px
    min-height: auto
  +v-xs
    padding: 80px 0 0
    min-height: auto
  .button
    +v-sm
      margin-left: 0 !important
      margin-right: 0 !important
    +v-xs
      margin-left: auto !important
      margin-right: auto !important

#about
  width: 100%
  height: auto
  min-height: 100vh
  min-height: auto

#career
  width: 100%
  height: auto
  min-height: 100vh
  background: $dark url('../../assets/power.webp') center center no-repeat
  background-blend-mode: overlay
  background-size: cover
  +v-sm
    min-height: auto

#sponsors
  width: 100%
  height: auto
  min-height: 100vh
  background: $dark url('../../assets/bg.webp') center center no-repeat
  background-blend-mode: multiply
  background-size: cover
  +v-sm
    min-height: auto

.bg
  &-001
    background: $light url('../../assets/about/a001.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto 40px !important
      height: 30vh
      order: 1 !important
  &-002
    background: $light url('../../assets/about/a002.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto 120px !important
      height: 30vh
      order: 1 !important
  &-003
    background: $light url('../../assets/about/a003.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto 120px !important
      height: 30vh
      order: 1 !important
  &-004
    background: $light url('../../assets/about/a0042.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto 120px !important
      height: 30vh
      order: 1 !important
  &-005
    background: $light url('../../assets/join.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 60px auto 0 !important
      height: 30vh
  &-h1
    background: $darkblue url('../../assets/hero/h1.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto !important
      height: 30vh
  &-h2
    background: $darkblue url('../../assets/hero/h2.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto !important
      height: 30vh
  &-h3
    background: $darkblue url('../../assets/hero/h3.webp') center center no-repeat
    background-size: cover
    +v-sm
      display: block
      width: 80%
      margin: 0 auto !important
      height: 30vh

.numbers-animated
  color: $theme
  font-size: 60px
  font-weight: 800 !important

#partners
  background-color: $white
  a
    img
      filter: grayscale(100%)
      opacity: .25
      transition: all ease-out .5s
    &:hover
      img
        opacity: 1
        filter: grayscale(0)

  *
    outline: none !important

.card-list
  margin: 0
  padding: 0
  list-style: none
  line-height: 2
  font-weight: 500

.icon
  &.big
    font-size: 40px
    padding-right: 24px

.mx-5
  &.mob-table
    +v-sm
      margin-left: -20px !important
      margin-right: 0 !important
    +v-xs
      margin-left: -20px !important
      margin-right: 0 !important

.table-mob
  td,
  th
    padding: 0 40px 10px
    +v-sm
      padding: 0 5%
    +v-xs
      zoom: .75
      padding: 0 5%
      transform: translateY(-40px)

header
  background-color: transparent
  margin-bottom: -104px
  a
    color: $white !important
    transition: all ease-out .3s
    &:hover
      color: $theme !important
  +v-sm
    position: fixed
    top: -20px
    left: 0
    width: 100%
    height: 80px
    max-height: 80px !important
    padding: 0 24px !important
    margin: 0 !important
    border-bottom: 1px solid $light
    background-color: $white
  #logo
    +v-sm
      transform: translate(-6px,14px)
  .menu
    display: flex
    justify-content: flex-end
    align-items: center
    +v-sm
      position: fixed
      display: block
      top: 60px
      left: 0
      margin: 0 !important
      padding: 0 !important
      width: 100%
      height: 100vh
      min-height: 100vh
      overflow: hidden
      background-color: $dark
      z-index: 999 !important
      overflow-x: hidden
      overflow-x: hidden
      transition: left ease-out .5s
      &.active
        left: 120%
      a
        display: flex
        align-items: center
        width: 100%
        height: 60px
        padding: 0 24px !important
        border-top: 1px solid $darkblue
        transform: translateX(-14px)

section
  +v-sm
    position: relative
    z-index: -1

.burger
  display: none
  +v-sm
    position: fixed
    top: 22px
    right: 48px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $darkblue
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $medium
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)

.mobile
  +v-sm
    transform: translate(-4px,11px)
  +v-xs
    transform: translate(22px,11px)

  .in
    +v-sm
      transform: translateY(1px)

.flex
  &.m-block
    +v-sm
      display: block
      margin: 0 !important
  &.sign
    img
      +v-sm
        display: inline-block
        margin: 4px !important

.m-none
  +v-sm
    display: none
.m-icon
  +v-sm
    height: 100px !important
    margin-bottom: 16px
    padding: 0
  +v-xs
    width: 48px !important
    height: 72px !important
    margin-bottom: 16px
    padding: 0
.m-30
  +v-sm
    margin-top: 30px
.m-pad
  +v-sm
    padding: 12px 24px !important
    margin-bottom: 30px
.m-bottom
  +v-sm
    padding-bottom: 20px !important
.m-40
  +v-sm
    padding-top: 40px !important

.m-none
  +v-sm
    display: none !important
  +v-xs
    display: none !important

.mob-auto
  +v-sm
    margin-left: auto !important
    margin-right: auto !important

.m-show
  display: none !important
  +v-sm
    display: block !important
  +v-xs
    display: block !important

.m-up-60
  +v-sm
    margin-top: -60px !important
  +v-xs
    margin-top: -80px !important
    margin-bottom: -60px

.m-down-60
  +v-sm
    margin-top: 40px !important
    margin-bottom: -40px !important
  +v-xs
    margin-top: -40px !important
    margin-bottom: -40px !important

.m-tr-60
  +v-sm
    transform: translateY(-60px)
    margin-bottom: -120px !important
  +v-xs
    transform: none
    margin-bottom: 0 !important

#career
  .w-60
    +v-xs
      max-width: 80% !important
  .button
    +v-xs
      max-width: 70% !important
      width: 70% !important
      min-width: 70% !important

.title
  &.w-60
    +v-xs
      max-width: 90% !important
      width: 90% !important
      min-width: 90% !important
      font-size: 1.15em !important

table
  +v-xs
    zoom: .65
    margin-top: -100px !important
    margin-left: auto !important
    margin-right: auto !important
