@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=auto')

$body: #fff
$theme: #dcae07
$dark: #252A38
$white: #fff
$light: #F5F5F5
$medium: #b6b3e5

$button: #184B88
$intro: $dark

$blue: #4881B5
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #9d9d9d

$basic: 'Manrope', sans-serif
$inter: $basic

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content

.md-60
  +v-md
    max-width: 60% !important
  +v-sm
    max-width: 90% !important
