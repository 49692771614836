.timeline-with-icons
  border-left: 1px solid hsl(0, 0%, 90%)
  position: relative
  left: 20px
  top: -20px
  list-style: none
  .timeline-item
    position: relative
    &:after
      position: absolute
      display: block
      top: 0
  .timeline-icon
    position: absolute
    left: -57px
    top: -10px
    font-size: 24px
    height: 48px
    width: 48px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    border: 1px solid hsl(0, 0%, 90%)
    background-color: $white
