.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 44px
  padding: 0 30px
  color: $white !important
  font-size: .8em
  font-weight: 600
  text-transform: uppercase
  border-radius: 4px
  transition: all ease-out .25s
  background-color: $marine !important
  outline: none !important
  &.theme
    background-color: $theme !important
  &.blue
    background-color: $blue !important
  +v-sm
    display: flex
    width: auto
    max-width: 40%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    min-width: 90% !important
    margin: 24px auto
    padding: 0
    font-size: 0.82em

a
  &.button
    &:hover
      color: $white !important
      background-color: $button !important
